// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cookie-richtlinie-js": () => import("./../src/pages/cookie-richtlinie.js" /* webpackChunkName: "component---src-pages-cookie-richtlinie-js" */),
  "component---src-pages-datenschutz-js": () => import("./../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-register-js": () => import("./../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */)
}

